const codeSolution = "datacrate";
const bombCodeSolution = "fsdg";
const wrongBombCodeKeys = "wa";

const startTime = Date.now();
const totalTime = 45 * 60 * 1000; // in ms

let remainingAttemps = 3;

let updateTimerInterval;

document.addEventListener("DOMContentLoaded", () => {
  document.getElementById("submit-code").addEventListener("click", () => {
    try {
      const code = document.getElementById("code").value.toLowerCase();
      if (code === codeSolution) onFoundCode();
    } catch (e) {}
    document.getElementById("code").value = "s";
  });

  updateTimerInterval = window.setInterval(updateTimer, 1000);
});

function onFoundCode() {
  document.getElementById("code-entry").classList.add("display-none");
  document.getElementById("bomb").classList.remove("display-none");

  updateStatusText();

  document.addEventListener("keydown", onKeyDown);
  document.addEventListener("keyup", onKeyUp);
}

function updateTimer() {
  const remainingMs = totalTime - (Date.now() - startTime);
  if (remainingMs < 0) {
    onGameOver();
  } else {
    const min = Math.floor(remainingMs / (60 * 1000));
    const sec = Math.floor((remainingMs / 1000) % 60);

    document.getElementById("timer").innerText = `${min
      .toString()
      .padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
  }
}

const keysDown = new Set();
function onKeyDown(event) {
  console.log(event);

  keysDown.add(event.key);
  updateKeySelection();
}
function onKeyUp(event) {
  console.log(event);

  keysDown.delete(event.key);
  updateKeySelection();
}

function updateKeySelection() {
  if (Array.from(wrongBombCodeKeys).some((key) => keysDown.has(key))) {
    onWrongBombCode();
  } else if (Array.from(bombCodeSolution).every((key) => keysDown.has(key))) {
    onFoundBombCode();
  }

  updateStatusText();
}

function cancelTimer() {
  window.clearInterval(updateTimerInterval);
}

function onGameOver() {
  cancelTimer();
  document.addEventListener("keydown", onKeyDown);
  document.addEventListener("keyup", onKeyUp);

  document.getElementById("code-entry").classList.add("display-none");
  document.getElementById("bomb").classList.add("display-none");
  document.getElementById("game-over").classList.remove("display-none");
}

function onWrongBombCode() {
  remainingAttemps--;
  if (remainingAttemps <= 0) {
    onGameOver();
  }
}

function onFoundBombCode() {
  cancelTimer();
  document.addEventListener("keydown", onKeyDown);
  document.addEventListener("keyup", onKeyUp);

  document.getElementById("code-entry").classList.add("display-none");
  document.getElementById("bomb").classList.add("display-none");
  document.getElementById("success").classList.remove("display-none");
}

function updateStatusText() {
  let statusText = `<p>Connected ${keysDown.size} / 4</p>`;
  statusText += `<p>Remaining attempts: ${remainingAttemps}</p>`;

  document.getElementById("bomb-status-text").innerHTML = statusText;
}
